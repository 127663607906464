import  React,{ useEffect, useRef } from "react"
import {useTexture } from "@react-three/drei";



 const Panorama = ({panoImageSource}) => {
    const equirectTexure = useTexture(panoImageSource);
    const gRef = useRef();
  
    useEffect(() => {
      gRef.current.scale(-1, 1, 1);
    }, []);
  
    return (
      <mesh>
        <sphereGeometry args={[500, 60, 40]} ref={gRef} />
        <meshStandardMaterial map={equirectTexure} />
      </mesh>
    );
  };

  export default Panorama