import  React,{ Suspense } from "react"
import { Environment,OrbitControls } from "@react-three/drei";
import { VRCanvas, DefaultXRControllers ,Hands} from "@react-three/xr";
import Panorama from "../components/panorama";

//intensity={1.2}
const App = ({location}) => {
    return (
    <div style={{height:"100vh"}}>
    <VRCanvas>
        <Suspense fallback={null}>
        <OrbitControls />
        <ambientLight />
        <Hands />
        {/* <Environment preset="apartment" />  */}
        {/* <pointLight position={[10, 10, 10]} /> */}
        <Panorama panoImageSource = {location.state && location.state.panoImageSource} />
        <DefaultXRControllers/>
        </Suspense>
    </VRCanvas>
  </div>
  )
}

export default App
